import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { LoadingService } from '../../app/loading/loading.service'
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(private loadingService: LoadingService) { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.loadingService.hide()
  }

  ngOnDestroy() {
    this.loadingService.show()
  }
}
