import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: '../app/group/group.module#GroupModule'
  },
  {
    path: 'theEmpressChiangMaiHotel',
    loadChildren: '../app/the-empress-chiangmai-hotel/the-empress-chiangmai-hotel.module#TheEmpressChiangmaiHotelModule'
  },
  {
    path: 'theParkHotel',
    loadChildren: '../app/the-empress-park/the-empress-park.module#TheEmpressParkModule'
  },
  {
    path: 'theEmpressPremier',
    loadChildren: '../app/the-empress-premier/the-empress-premier.module#TheEmpressPremierModule'
  },
  {
    path: 'ecc',
    loadChildren: '../app/ecc/ecc.module#EccModule'
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
