
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import { LoadingService } from '../loading/loading.service'


@NgModule({
    declarations: [
        LoadingComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [LoadingComponent],
    providers: [LoadingService]
})
export class LoadingModule {
}
