import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { jsonpCallbackContext } from '@angular/common/http/src/module';

@Injectable({
  providedIn: 'root'
})
export class AllService {
  private URI = environment.uri;
  constructor(
    private http: HttpClient
  ) { }

  getNews() {
    return this.http.get(this.URI + 'news/getNewsThree').pipe(
      map(res => res)
    )
  }

  getFestival() {
    return this.http.get(this.URI + 'Festivals/getFestivals').pipe(
      map(res => res)
    )
  }

  getTourist() {
    return this.http.get(this.URI + 'touristAttractions/getTouristAttractions').pipe(
      map(res => res)
    )
  }
}
