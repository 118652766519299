import { Component, OnInit, Renderer2, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import { LoadedJSToElementMobirus } from '../../shared/loadedJSToElementMobirise'
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoadingService } from '../../loading/loading.service'
import {
  Router, NavigationStart, NavigationCancel, NavigationEnd
} from '@angular/router';

import { AllService } from '../all.service'

@Component({
  selector: 'app-main-group',
  templateUrl: './main_group.component.html',
  styleUrls: ['./main_group.component.scss']
})

export class MainGroupComponent implements OnInit, AfterViewInit, OnDestroy {
  private detail: Festival
  private loadedJS: LoadedJSToElementMobirus = new LoadedJSToElementMobirus()
  loadAPI: Promise<any>;
  constructor(private http: Http, private renderer: Renderer2, private loadingService: LoadingService, private router: Router,
    private allService: AllService) {
    this.loadAPI = new Promise((resolve) => {
      this.loadedJS.init(this.renderer)
      resolve(true);
    });
    // this.getJSon().subscribe(data => this.detail = data)
  }

  public name = ''
  public picture = ''
  public map = ''
  public article = ''
  public festivals: any
  public selectedFestival: any
  public news: any
  public tourists: any
  public selectedTourist: any


  public animationDelay = '0.5s'

  ngOnInit() {
    this.allService.getFestival().subscribe(res => {

      this.festivals = res
    })
    this.allService.getNews().subscribe(res => {

      this.news = res
      this.news.forEach(data => {
        data.Paragraph2 = data.Paragraph2.replace(/<Paragraph2>/g, '<p class="mbr-text card-text mbr-fonts-style mbr-lighter display-7">')
        data.Paragraph2 = data.Paragraph2.replace(/<\/Paragraph2>/g, '</p>')


      });

    })
    this.allService.getTourist().subscribe(res => {
      this.tourists = res
    })
  }

  ngAfterViewInit() {
    this.loadingService.hide()
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.loadingService.show()
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          this.loadingService.hide()
        }
      });

  }

  ngOnDestroy() {
    this.loadingService.show()
  }

  getJSon() {
    return this.http.get('./assets/touristAttractionAndFestival.json').pipe(
      map((res: any) => res.json())
    )
  }

  @HostListener('window:scroll') onScroll() {
    this.scrollFunction();
  }

  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById('myBtn').style.display = 'block';
    } else {
      document.getElementById('myBtn').style.display = 'none';
    }
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }


  changeFestival(data) {
    this.selectedFestival = data
  }

  changeTourist(data) {
    this.selectedTourist = data
  }



}

export class Festival {
  1: Array<string>;
  2: Array<string>;
  3: Array<string>;
  4: Array<string>;
  5: Array<string>;
  6: Array<string>;
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit = 180, completeWords = false, ellipsis = '...') {
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    return `${value.substr(0, limit)}${ellipsis}`;
  }
}
