import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { MainGroupComponent } from './main_group/main_group.component';
import { LoadingModule } from '../loading/landing.module';
import { TruncatePipe } from './main_group/main_group.component'
@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    LoadingModule,
    HttpClientModule
  ],
  declarations: [
    MainGroupComponent,
    TruncatePipe
  ]
})
export class GroupModule { }
