import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private isShow  = new BehaviorSubject(true);
    currentStatus = this.isShow.asObservable();
    constructor() { }

    show() {
        this.isShow.next(true)
    }

    hide() {
        this.isShow.next(false)
    }
}