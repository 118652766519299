import { Component, OnInit, Renderer2, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoadedJSToElementMobirus } from './shared/loadedJSToElementMobirise'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'hotelEmpressProject';
  loadAPI: Promise<any>;
  screenHeight = 0;
  screenWidth = 0;

  constructor(private renderer: Renderer2, private router: Router) {
    this.onResize()
  }

  @HostListener('window:resize', ['$event'])

  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.setMidleOfRightSideBar()
  }

  ngOnInit() {
    this.setMidleOfRightSideBar()
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  openNav() {
    if (window.innerWidth > 768) {
      document.getElementById('mySidenav').style.width = '100px';
    } else {
      document.getElementById('mySidenav').style.width = '80px';
    }

    document.getElementById('btnOpenRightMenu').style.display = 'none';
  }

  closeNav() {
    document.getElementById('mySidenav').style.width = '0';
    document.getElementById('btnOpenRightMenu').style.display = 'block';
  }

  setMidleOfRightSideBar() {
    if (document.getElementById('mySidenav')) {
      document.getElementById('mySidenav').style.paddingTop =
        (this.screenHeight > 600) ? (this.screenHeight / 2 - 260).toString() + 'px' : 50 + 'px'
    }
    if (document.getElementById('btnOpenRightMenu')) {
      document.getElementById('btnOpenRightMenu').style.top = (this.screenHeight / 2 - 200).toString() + 'px'
    }
  }


}
