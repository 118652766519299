import { Component, OnInit, Input } from '@angular/core';
import { LoadingService } from './loading.service'
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  show: boolean
  constructor(private loadingService: LoadingService) {
   this.loadingService.currentStatus.subscribe(response => { this.show = response})
   }

  ngOnInit() {
  }

}
