import { Renderer2 } from '@angular/core';
export class LoadedJSToElementMobirus {
    private renderer: Renderer2
    constructor() {
    }

    init(render: Renderer2) {
        this.renderer = render

        this.removeElement()

        // this.addJsToElement('../../assets/mobirise/assets/web/assets/jquery/jquery.min.js')

        this.addJsToElement('../../assets/mobirise/assets/popper/popper.min.js')

        this.addJsToElement('../../assets/mobirise/assets/tether/tether.min.js')

        // this.addJsToElement('../../assets/mobirise/assets/bootstrap/js/bootstrap.min.js')

        this.addJsToElement('../../assets/mobirise/assets/bootstrap-datepicker/js/bootstrap-datepicker.js')

        // this.addJsToElement('../../assets/mobirise/assets/dropdown/js/script.min.js')

        this.addJsToElement('../../assets/mobirise/assets/touchswipe/jquery.touch-swipe.min.js')

        // this.addJsToElement('../../assets/mobirise/assets/mbr-booking-datepicker/mbr-booking-datepicker.min.js')

        this.addJsToElement('../../assets/mobirise/assets/smoothscroll/smooth-scroll.js')

        this.addJsToElement('../../assets/mobirise/assets/theme/js/script.js')

        this.addJsToElement('../../assets/mobirise/assets/formoid/formoid.min.js')

        this.addJsToElement('../../assets/mobirise/assets/gallery/player.min.js')

        this.addJsToElement('../../assets/mobirise/assets/gallery/script.js')
        this.addJsToElement('../../assets/mobirise/assets/bootstrapcarouselswipe/bootstrap-carousel-swipe.js')
        this.addJsToElement('../../assets/mobirise/assets/parallax/jarallax.min.js')
        this.addJsToElement('../../assets/mobirise/assets/imagesloaded/imagesloaded.pkgd.min.js')

    }


    // addJsToElement(src: string): HTMLScriptElement {
    //     const script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.src = src;
    //     this.renderer.appendChild(document.body, script);
    //     return script;

    // }

    addJsToElement(src: string) {

        const node = document.createElement('script');
        node.src = src
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    removeElement() {
        const nodes = document.head.getElementsByTagName('script');
        const a = nodes.length
        if (a > 0) {
            const b = nodes[0].parentNode
            for (let index = 0; index < a; index++) {
                document.head.removeChild(nodes[0])
            }
        }
        return true
    }

}

